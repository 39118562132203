import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Policy from './components/Policy';
import Support from './components/Support';
import Service from './components/Service';
import RefundPolicy from './components/RefundPolicy';
import About from './components/About';
// import AllInOne from './components/AllInOne';
import Testimonial from './components/Testimonial';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Navbar from './components/Navbar'
import Pricing from './components/Pricing';
import OurOffering from './components/OurOffering'
import Map from './components/Map'
import Placed from './components/Placed'

function App() {
  return (
    <Router>
    <>
      <Navbar />
      <Hero />
      <Placed />
      <About />
      <OurOffering />
      {/* <AllInOne /> */}
      <Pricing />
      <Testimonial />
      <Map />
      <Footer />
      
      <Routes>
  <Route path="/privacypolicy" element={<Policy />} />
  <Route path="/support" element={<Support />} />
  <Route path="/service" element={<Service />} />
  <Route path="/RefundPolicy" element={<RefundPolicy />} />
</Routes>

    </>
    </Router>
  );
}

export default App;
